import { clamp } from "@warrenio/utils/clamp";
import { HOURS } from "@warrenio/utils/timeUnits";

export function abbreviateNumber(number: number): string {
    const SI_SYMBOL = ["", "k", "M", "B", "T", "Q"];
    const tier = (Math.log10(number) / 3) | 0;
    if (tier === 0) return String(number);
    const suffix = SI_SYMBOL[tier];
    const scale = 10 ** (tier * 3);
    const scaled = number / scale;
    return scaled + suffix;
}

export function getHoursPassedAndLeft(dateString: string, now = new Date()) {
    // Parse the year and month from the date string
    // (NB: since this is only for parsing, do not use UTC, as otherwise the month can be off by 1 if the date is in the very beginning/end of the month)
    const givenDate = new Date(dateString);
    const year = givenDate.getFullYear();
    const month = givenDate.getMonth(); // 0-based index (0 = January, 11 = December)

    // Get start of months in UTC
    const startOfMonth = new Date(Date.UTC(year, month, 1, 0, 0, 0));
    const startOfNextMonth = new Date(Date.UTC(year, month + 1, 1, 0, 0, 0)); // First day of next month

    const monthHours = (startOfNextMonth.valueOf() - startOfMonth.valueOf()) / HOURS;

    // Clamp to 0 in case of future date
    // Clamp to `monthHours` in case of a completely passed month
    const hoursPassed = clamp((now.valueOf() - startOfMonth.valueOf()) / HOURS, 0, monthHours);
    const hoursLeft = monthHours - hoursPassed;

    return { passed: Math.floor(hoursPassed), left: Math.ceil(hoursLeft) };
}
