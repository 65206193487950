import D from "./Dashboard.module.css";

import { type ReactNode, useEffect, useMemo, useState } from "react";
import { isNumber } from "remeda";
import { WTooltip } from "../../components/WTooltip.tsx";
import { cn } from "../../utils/classNames.ts";

const PROGRESS_UNIT = 0.01;
const PROGRESS_TIMEOUT = 5;

export interface DonutChartItemProps {
    value: number;
    color: string;
}

function getArcPath(start: number, end: number, innerRadius: number, outerRadius: number) {
    if (start === end) {
        return ""; // Return an empty path if start and end angles are the same
    }

    if (end - start >= 1) {
        // Full circle path
        const x = outerRadius * Math.sin(0);
        const y = outerRadius * -Math.cos(0);
        return `M ${x},${y}
                A ${outerRadius} ${outerRadius} 0 1 1 ${-x},${-y}
                A ${outerRadius} ${outerRadius} 0 1 1 ${x},${y}
                M ${innerRadius},${0}
                A ${innerRadius} ${innerRadius} 0 1 0 ${-innerRadius},${0}
                A ${innerRadius} ${innerRadius} 0 1 0 ${innerRadius},${0} Z`;
    }

    const startAngle = start * Math.PI * 2;
    const endAngle = end * Math.PI * 2;
    const x1 = innerRadius * Math.sin(startAngle);
    const y1 = innerRadius * -Math.cos(startAngle);
    const x2 = outerRadius * Math.sin(startAngle);
    const y2 = outerRadius * -Math.cos(startAngle);
    const x3 = outerRadius * Math.sin(endAngle);
    const y3 = outerRadius * -Math.cos(endAngle);
    const x4 = innerRadius * Math.sin(endAngle);
    const y4 = innerRadius * -Math.cos(endAngle);
    const bigArc = end - start >= 0.5;
    const outerFlags = bigArc ? "1 1 1" : "0 0 1";
    const innerFlags = bigArc ? "1 1 0" : "1 0 0";
    return `M ${x1},${y1} L ${x2},${y2} A ${outerRadius} ${outerRadius} ${outerFlags} ${x3},${y3}
        L ${x4},${y4} A ${innerRadius} ${innerRadius} ${innerFlags} ${x1},${y1} Z`;
}

export function DonutChart({
    items,
    tooltip,
    percentUsed,
}: {
    items: DonutChartItemProps[];
    tooltip?: ReactNode;
    percentUsed?: number;
}) {
    const [visiblePart, setVisiblePart] = useState(0);

    const width = 300;
    const height = width;
    const outerRadius = width / 2 - 1;
    const innerRadius = outerRadius - 66;

    useEffect(() => {
        if (visiblePart < 1) {
            setTimeout(() => setVisiblePart(visiblePart + PROGRESS_UNIT), PROGRESS_TIMEOUT);
        }
    }, [visiblePart]);

    const segments = useMemo(() => {
        const sum = items.reduce((sum: number, item) => sum + item.value, 0);
        let start = 0;
        return items.map((item) => {
            const delta = (item.value / sum) * visiblePart;
            const path = getArcPath(start, start + delta, innerRadius, outerRadius);
            start += delta;
            return { ...item, path };
        });
    }, [items, innerRadius, outerRadius, visiblePart]);

    const svg = (
        <svg
            width="100%"
            height="100%"
            viewBox={`0 0 ${width} ${height}`}
            // Assume the content is also represented elsewhere and the donut is purely decorative
            role="presentation"
        >
            <g transform={`translate(${width / 2}, ${height / 2})`}>
                {segments.map((segment) => (
                    <path key={segment.color} stroke={segment.color} fill={segment.color} d={segment.path} />
                ))}
            </g>
        </svg>
    );

    return (
        <div className={D.Donut}>
            {tooltip ? (
                <WTooltip reverse text={tooltip}>
                    {svg}
                </WTooltip>
            ) : (
                svg
            )}

            {isNumber(percentUsed) && <div className={D.Center}>{percentUsed.toFixed(1)}%</div>}
        </div>
    );
}

export interface BigDonutProps {
    items: DonutChartItemProps[];
    displayName?: string;
    percentUsed: number;
    tooltipContent: ReactNode;
    location: string;
    total: string | number;
}

export function BigDonut({ items, displayName, tooltipContent, percentUsed, location, total }: BigDonutProps) {
    return (
        <div className={D.Chart}>
            <DonutChart items={items} tooltip={tooltipContent} percentUsed={percentUsed} />

            <div className="px-4 pt-2">
                <div className="flex justify-between gap-2">
                    <div>
                        <b>{location}</b>
                    </div>

                    <div className="font-size-small">Total: {total}</div>
                </div>

                {!!displayName && (
                    <div className="pb-1">
                        <b>{displayName}</b>
                    </div>
                )}

                {tooltipContent}
            </div>
        </div>
    );
}

export interface SmallDonutProps {
    items: DonutChartItemProps[];
    title: string;
    total: string | number;
    tooltip: ReactNode;
    marginLeft?: boolean;
}

export function SmallDonut({ items, title, total, tooltip, marginLeft }: SmallDonutProps) {
    return (
        <div className={cn("flex gap-0.5rem text-right", marginLeft && "ml-auto")}>
            <div>
                <div className="color-muted">{title}</div>
                <div className="font-size-heading lh-6">{total}</div>
            </div>

            <div className="w-2.5rem min-w-2.5rem">
                <DonutChart items={items} tooltip={tooltip} />
            </div>
        </div>
    );
}
