import { Suspense, type ReactNode } from "react";
import { Loading } from "../../components/loading/Loading.tsx";
import { Separator } from "../../components/Separator.tsx";
import { WErrorBoundary } from "../../modules/error/ErrorFallback.tsx";
import { mcn, type BaseProps } from "../../utils/baseProps.ts";

export function LightBlock({
    children,
    title,
    subtitle,
    loadingHeight,
    ...props
}: {
    children: ReactNode;
    title?: ReactNode;
    subtitle?: ReactNode;
    loadingHeight: string;
} & BaseProps) {
    return (
        <section {...mcn("lightBlock overflow-hidden", props)}>
            {title != null && (
                <>
                    <h2 className="font-size-heading">{title}</h2>
                    {subtitle != null && <p className="pb-3">{subtitle}</p>}
                    <Separator />
                </>
            )}
            <WErrorBoundary tags={["admin", "LightBlock"]}>
                <Suspense
                    fallback={
                        <div className="flex justify-center items-center" style={{ minHeight: loadingHeight }}>
                            <Loading icon="none" />
                        </div>
                    }
                >
                    {children}
                </Suspense>
            </WErrorBoundary>
        </section>
    );
}
