import { useState } from "react";
import { Label } from "react-aria-components";
import { ActionMenu } from "../components/ActionMenu.tsx";
import { WButton } from "../components/button/WButton.tsx";
import { getPreferredLocation, type LocationWithEnabled } from "../modules/location/location.ts";
import { LocationSelectCustom } from "../modules/location/LocationSelect.tsx";
import { usePricingLocations } from "../modules/location/query.ts";

export function AdminLocationSelect({ onChange }: { onChange: (value: LocationWithEnabled) => void }) {
    const locations = usePricingLocations();
    const location = getPreferredLocation(locations);
    const [selectedLocation, setSelectedLocation] = useState<LocationWithEnabled>(location);

    function onChangeLocation(location: LocationWithEnabled) {
        setSelectedLocation(location);
        onChange(location);
    }

    return (
        <div className="flex items-center">
            <Label className="font-size-small pr-1">Location:</Label>
            <LocationSelectCustom items={locations} value={selectedLocation} onChange={onChangeLocation} />
        </div>
    );
}

interface LocationType {
    id: string;
    title: string;
    isChecked: boolean;
}

export function AdminLocationMenu({
    value,
    locations,
    noLocationEnabled,
    onChange,
}: {
    value: string[];
    locations: LocationWithEnabled[];
    noLocationEnabled?: boolean;
    onChange: (value: string[]) => void;
}) {
    const [items, setItems] = useState<LocationType[]>(() => {
        const item = {
            id: "",
            title: "No location",
            isChecked: value.includes(""),
        };

        const items: LocationType[] = locations.map((item) => {
            return {
                id: item.slug,
                title: item.display_name,
                isChecked: value.includes(item.slug),
            };
        });

        if (noLocationEnabled) {
            items.push(item);
        }

        return items;
    });

    function onToggle(id: string) {
        setItems((prevItems) => {
            const updated = prevItems.map((item) => (item.id === id ? { ...item, isChecked: !item.isChecked } : item));
            onChange(updated.filter((item) => item.isChecked).map((item) => item.id));
            return updated;
        });
    }

    function setAllChecked(isChecked: boolean) {
        setItems((prevItems) => {
            const updated = prevItems.map((item) => ({ ...item, isChecked }));
            onChange(updated.filter((item) => item.isChecked).map((item) => item.id));
            return updated;
        });
    }

    const checkedLocations = items.filter((item) => item.isChecked);

    return (
        <div className="flex items-center">
            <Label className="font-size-small pr-1">Location:</Label>
            <ActionMenu
                header={
                    <div className="flex-1 flex gap-1rem justify-between">
                        <WButton color="primary" variant="ghost" size="xs" action={() => setAllChecked(true)}>
                            Select All
                        </WButton>
                        <div>|</div>
                        <WButton color="primary" variant="ghost" size="xs" action={() => setAllChecked(false)}>
                            Unselect All
                        </WButton>
                    </div>
                }
                onAction={({ id }) => onToggle(id)}
                selectedKeys={checkedLocations.map((item) => item.id)}
                items={items}
                selectionMode="multiple"
            >
                <WButton color="default" variant="basic" size="md" ariaLabel="Location" action={undefined}>
                    <span className="text-ellipsis max-w-15rem">
                        {checkedLocations.length
                            ? items.length === checkedLocations.length
                                ? "All"
                                : checkedLocations.map((item) => item.title).join(", ")
                            : "Please select"}
                    </span>
                </WButton>
            </ActionMenu>
        </div>
    );
}
