import type { ZRColor } from "echarts/types/dist/shared";

export function getPredictionGradient(colorStripe: string, colorBg: string): ZRColor {
    return {
        type: "linear",
        x: 0,
        y: 0,
        x2: 1,
        y2: 1,
        colorStops: [
            {
                offset: 0,
                color: colorStripe,
            },
            {
                offset: 0.01,
                color: colorStripe,
            },
            {
                offset: 0.01,
                color: colorBg,
            },
            {
                offset: 0.05,
                color: colorBg,
            },
            {
                offset: 0.05,
                color: colorStripe,
            },
            {
                offset: 0.06,
                color: colorStripe,
            },
            {
                offset: 0.06,
                color: colorBg,
            },
            {
                offset: 0.1,
                color: colorBg,
            },

            {
                offset: 0.1,
                color: colorStripe,
            },
            {
                offset: 0.11,
                color: colorStripe,
            },
            {
                offset: 0.11,
                color: colorBg,
            },
            {
                offset: 0.15,
                color: colorBg,
            },
            {
                offset: 0.15,
                color: colorStripe,
            },
            {
                offset: 0.16,
                color: colorStripe,
            },
            {
                offset: 0.16,
                color: colorBg,
            },
            {
                offset: 0.2,
                color: colorBg,
            },

            {
                offset: 0.2,
                color: colorStripe,
            },
            {
                offset: 0.21,
                color: colorStripe,
            },
            {
                offset: 0.21,
                color: colorBg,
            },
            {
                offset: 0.25,
                color: colorBg,
            },
            {
                offset: 0.25,
                color: colorStripe,
            },
            {
                offset: 0.26,
                color: colorStripe,
            },
            {
                offset: 0.26,
                color: colorBg,
            },
            {
                offset: 0.3,
                color: colorBg,
            },

            {
                offset: 0.3,
                color: colorStripe,
            },
            {
                offset: 0.31,
                color: colorStripe,
            },
            {
                offset: 0.31,
                color: colorBg,
            },
            {
                offset: 0.35,
                color: colorBg,
            },
            {
                offset: 0.35,
                color: colorStripe,
            },
            {
                offset: 0.36,
                color: colorStripe,
            },
            {
                offset: 0.36,
                color: colorBg,
            },
            {
                offset: 0.4,
                color: colorBg,
            },

            {
                offset: 0.4,
                color: colorStripe,
            },
            {
                offset: 0.41,
                color: colorStripe,
            },
            {
                offset: 0.41,
                color: colorBg,
            },
            {
                offset: 0.45,
                color: colorBg,
            },
            {
                offset: 0.45,
                color: colorStripe,
            },
            {
                offset: 0.46,
                color: colorStripe,
            },
            {
                offset: 0.46,
                color: colorBg,
            },
            {
                offset: 0.5,
                color: colorBg,
            },

            {
                offset: 0.5,
                color: colorStripe,
            },
            {
                offset: 0.51,
                color: colorStripe,
            },
            {
                offset: 0.51,
                color: colorBg,
            },
            {
                offset: 0.55,
                color: colorBg,
            },
            {
                offset: 0.55,
                color: colorStripe,
            },
            {
                offset: 0.56,
                color: colorStripe,
            },
            {
                offset: 0.56,
                color: colorBg,
            },
            {
                offset: 0.6,
                color: colorBg,
            },

            {
                offset: 0.6,
                color: colorStripe,
            },
            {
                offset: 0.61,
                color: colorStripe,
            },
            {
                offset: 0.61,
                color: colorBg,
            },
            {
                offset: 0.65,
                color: colorBg,
            },
            {
                offset: 0.65,
                color: colorStripe,
            },
            {
                offset: 0.66,
                color: colorStripe,
            },
            {
                offset: 0.66,
                color: colorBg,
            },
            {
                offset: 0.7,
                color: colorBg,
            },

            {
                offset: 0,
                color: colorStripe,
            },
            {
                offset: 0.01,
                color: colorStripe,
            },
            {
                offset: 0.01,
                color: colorBg,
            },
            {
                offset: 0.05,
                color: colorBg,
            },
            {
                offset: 0.05,
                color: colorStripe,
            },
            {
                offset: 0.06,
                color: colorStripe,
            },
            {
                offset: 0.06,
                color: colorBg,
            },
            {
                offset: 0.7,
                color: colorBg,
            },

            {
                offset: 0.7,
                color: colorStripe,
            },
            {
                offset: 0.71,
                color: colorStripe,
            },
            {
                offset: 0.71,
                color: colorBg,
            },
            {
                offset: 0.75,
                color: colorBg,
            },
            {
                offset: 0.75,
                color: colorStripe,
            },
            {
                offset: 0.76,
                color: colorStripe,
            },
            {
                offset: 0.76,
                color: colorBg,
            },
            {
                offset: 0.8,
                color: colorBg,
            },

            {
                offset: 0.8,
                color: colorStripe,
            },
            {
                offset: 0.81,
                color: colorStripe,
            },
            {
                offset: 0.81,
                color: colorBg,
            },
            {
                offset: 0.85,
                color: colorBg,
            },
            {
                offset: 0.85,
                color: colorStripe,
            },
            {
                offset: 0.86,
                color: colorStripe,
            },
            {
                offset: 0.86,
                color: colorBg,
            },
            {
                offset: 0.9,
                color: colorBg,
            },

            {
                offset: 0.9,
                color: colorStripe,
            },
            {
                offset: 0.91,
                color: colorStripe,
            },
            {
                offset: 0.91,
                color: colorBg,
            },
            {
                offset: 0.95,
                color: colorBg,
            },
            {
                offset: 0.95,
                color: colorStripe,
            },
            {
                offset: 0.96,
                color: colorStripe,
            },
            {
                offset: 0.96,
                color: colorBg,
            },
            {
                offset: 1,
                color: colorBg,
            },
        ],
        global: false,
    };
}
