import D from "./Dashboard.module.css";

import type { ReactNode } from "react";
import type { BaseProps } from "../../utils/baseProps.ts";
import { mcn } from "../../utils/baseProps.ts";

export function HeadingBlock({
    children,
    title,
    subtitle,
    ...props
}: {
    children?: ReactNode;
    title: ReactNode;
    subtitle: ReactNode;
} & BaseProps) {
    return (
        <div {...mcn(D.HeadingBlock, props)}>
            <div className="color-muted">{title}</div>
            <div className="font-size-heading lh-6">{subtitle}</div>
            {children}
        </div>
    );
}
